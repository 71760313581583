import { onlyAuthenticated } from 'auxiliaries/helpers/authHelpers';

import {
  CarInfoMyAd,
  PlateInfoMyAd,
  MobileInfoMyAd,
  ChangePasswordBox,
  DealerBox,
  EditMyAd,
  MyAds,
  MyAdsList,
  MyProfile,
  MyProfileBox,
  MySavedSearch,
  MyWishlist,
  PersonalInfoMyAd,
  PreviewMyAd,
  UpdateImagesMyAd,
  MyPayments,
  SavedDetails,
  NewPaymentDetails,
} from 'constants/lazies/user';

export const routes = [
  {
    path: '/user/my-profile',
    render: props => onlyAuthenticated(MyProfile, props),
    routes: [
      {
        path: '/user/my-profile/change-password',
        component: ChangePasswordBox,
      },
      {
        path: '/user/my-profile/dealer',
        component: DealerBox,
      },
      {
        path: '/',
        component: MyProfileBox,
      },
    ],
  },
  {
    path: '/user/my-wishlist',
    render: props => onlyAuthenticated(MyWishlist, props),
  },
  {
    path: '/user/my-payments',
    render: props => onlyAuthenticated(MyPayments, props),
    routes: [
      {
        path: '/user/my-payments/new-payment-details',
        component: NewPaymentDetails,
      },
      {
        path: '/',
        component: SavedDetails,
      },
    ],
  },
  {
    path: '/user/my-ads',
    render: props => onlyAuthenticated(MyAds, props),
    routes: [
      {
        path: '/user/my-ads/edit/:id/car-info',
        component: CarInfoMyAd,
      },
      {
        path: '/user/my-ads/edit/:id/plate-info',
        component: PlateInfoMyAd,
      },
      {
        path: '/user/my-ads/edit/:id/mobile-info',
        component: MobileInfoMyAd,
      },
      {
        path: '/user/my-ads/edit/:id/personal-info',
        component: PersonalInfoMyAd,
      },
      {
        path: '/user/my-ads/edit/:id/update-images',
        component: UpdateImagesMyAd,
      },
      {
        path: '/user/my-ads/edit/:id',
        component: EditMyAd,
      },
      {
        path: '/user/my-ads/preview/:id',
        component: PreviewMyAd,
      },
      {
        path: '/',
        component: MyAdsList,
      },
    ],
  },
  {
    path: '/user/my-saved-search',
    render: props => onlyAuthenticated(MySavedSearch, props),
  },
];
