import { call, put } from 'redux-saga/effects';
import { Creators } from 'rdx/actions/getOptionsForFieldActions';

export function* request(api, { data }) {
  try {
    const response = yield call(api.getOptionsForFieldRequest, data);
    const onSuccess = data.onSuccess;

    if (response && response.ok) {
      if (response.data && response.data.availableOptions) {
        const newData = {};
        newData[data.type] = response.data.availableOptions;
        // console.log('Results: ', newData);
        yield put(Creators.success(newData));

        if (onSuccess) {
          yield onSuccess(newData);
        }
      } else {
        // console.log('Results: ', response.data);
        yield put(Creators.success(response.data));

        if (onSuccess) {
          yield onSuccess(response.data);
        }
      }
    } else {
      // console.log('Failed: ', response.data);
      yield put(Creators.failure(response.data));
    }
  } catch (e) {
    // console.log('Failed: ', e.message, e);
    yield put(Creators.failure(e.message));
  }
}
