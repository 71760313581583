import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/forgotPasswordActions';

function* onSuccessRequest() {
  yield put(push('/reset-password'));
}

export function* request(api, { data }) {
  yield createBasicSaga(
    api.forgotPasswordRequest,
    data,
    Creators,
    onSuccessRequest
  );
}
