import { lazy } from 'react';

export const App = lazy(() => import('App'));

export const AboutUs = lazy(() =>
  import('components/AboutUs/AboutUs')
);

export const PrivacyPolicy = lazy(() =>
  import('components/PrivacyPolicy/PrivacyPolicy')
);

export const TermsAndConditions = lazy(() =>
  import('components/TermsAndConditions/TermsAndConditions')
);

export const Landing = lazy(() =>
  import('components/Landing/Landing')
);

export const LoginBox = lazy(() => import('containers/LoginBox'));

export const SocialLoginBox = lazy(() =>
  import('components/SocialLoginBox/SocialLoginBox')
);

export const Login = lazy(() => import('components/Login/Login'));

export const Register = lazy(() => import('containers/Register'));

export const ForgotPassword = lazy(() =>
  import('containers/ForgotPassword')
);

export const ResetPassword = lazy(() =>
  import('containers/ResetPassword')
);

export const DealerRegister = lazy(() =>
  import('components/DealerRegister/DealerRegister')
);

export const Navbar = lazy(() =>
  import('components/common/Navbar/Navbar')
);

export const Footer = lazy(() =>
  import('components/common/Footer/Footer')
);
