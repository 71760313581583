import React from 'react';
import { prop } from 'ramda';
import { Redirect } from 'react-router-dom';

import api from 'rdx/sagas/api';
import { TOKEN, TOKEN_REFRESH, TOKEN_EXPIRE_DATE, USER_ID, USER_NAME } from 'constants/index';

export const isAuthenticated = () => {
  const tokenExpireDate = localStorage.getItem(TOKEN_EXPIRE_DATE);
  if (tokenExpireDate !== null) {
    checkIsTokenValid()
    const tokenExpireMinutes = ((parseInt(tokenExpireDate, 10) - Date.now()) / 1000 / 60)
    if (tokenExpireMinutes > 30) {
      return true;
    }

    api.loginRequest({
      grant_type: 'refresh_token',
      username: localStorage.getItem(USER_NAME),
      refresh_token: localStorage.getItem(TOKEN_REFRESH)
    })
    .then(({ status, data }) => {
      if (status === 200) {
        addLocalAuthData(data)
      }
    })
  }

  return false;
}

export const onlyAuthenticated = (WrappedComponent, props) =>
  isAuthenticated() ? (
    <WrappedComponent {...props} />
  ) : (
    <Redirect to="/login" />
  );

export const addLocalAuthData = data => {
  localStorage.setItem(TOKEN, prop('access_token', data));
  localStorage.setItem(TOKEN_REFRESH, prop('refresh_token', data));
  localStorage.setItem(TOKEN_EXPIRE_DATE, prop('expires_at', data));
};

export const removeLocalAuthData = () => {
  api.myProfileUpdateRequest({
    firebaseToken: ''
  })
  .then(() => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKEN_REFRESH);
    localStorage.removeItem(TOKEN_EXPIRE_DATE);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_NAME);
    window.location.reload();
  })
};

export const checkIsTokenValid = () => {
  const tokenExpireDate = localStorage.getItem(TOKEN_EXPIRE_DATE);
  if (tokenExpireDate !== null) {
    if (Date.now() >= parseInt(tokenExpireDate, 10)) {
      removeLocalAuthData();
      window.location.reload();
    }
  }
};
