import { onlyAuthenticated } from 'auxiliaries/helpers/authHelpers';

import {
  PlaceAdPlateNumberInformation,
  PlaceAdPlateNumberContactInfo,
  PlaceAdPlateNumberReview,
} from 'constants/lazies/placeAd/plateNumber';

export const routes = [
  {
    path: '/place-ad/plate-number/information',
    render: props =>
      onlyAuthenticated(PlaceAdPlateNumberInformation, props),
  },
  {
    path: '/place-ad/plate-number/contact-info',
    render: props =>
      onlyAuthenticated(PlaceAdPlateNumberContactInfo, props),
  },
  {
    path: '/place-ad/plate-number/review',
    render: props =>
      onlyAuthenticated(PlaceAdPlateNumberReview, props),
  },
];
