import { call, put } from 'redux-saga/effects';
import { Creators } from 'rdx/actions/getAllOptionsForFieldActions';

export function* request(api, { data }) {
  try {
    const response = yield call(
      api.getAllOptionsForFieldRequest,
      data
    );
    const onSuccess = data.onSuccess;

    if (response && response.ok) {
      const newData = {};
      newData[data.fieldName] = response.data.data;
      // console.log('Results: ', newData);
      yield put(Creators.success(newData));

      if (onSuccess) {
        yield onSuccess(newData);
      }
    } else {
      // console.log('Failed: ', response.data);
      yield put(Creators.failure(response.data));
    }
  } catch (e) {
    // console.log('Failed: ', e.message, e);
    yield put(Creators.failure(e.message));
  }
}
