import { lazy } from 'react';

export const PlaceAdPhoneNumberInformation = lazy(() =>
  import('containers/PlaceAd/phoneNumber/Information')
);

export const PlaceAdPhoneNumberContactInfo = lazy(() =>
  import('containers/PlaceAd/phoneNumber/ContactInfo')
);

export const PlaceAdPhoneNumberReview = lazy(() =>
  import('containers/PlaceAd/phoneNumber/Review')
);
