import {
  Listing,
  PlateListing,
  PhoneListing,
  CarListing,
  ListingChooseCategory,
} from 'constants/lazies/listing';

export const routes = [
  {
    path: '/listing',
    component: Listing,
    routes: [
      {
        path: '/listing/plate',
        component: PlateListing,
      },
      {
        path: '/listing/phone',
        component: PhoneListing,
      },
      {
        path: '/listing/car',
        component: CarListing,
      },
      {
        path: '/listing',
        component: ListingChooseCategory,
      },
    ],
  },
];
