import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/myProfileActions';

export function* request(api, { data }) {
  yield createBasicSaga(
    api.myProfileRequest,
    data,
    Creators,
    response => {
      if (
        (response.success || response.user) &&
        data &&
        typeof data.onSuccess === 'function'
      ) {
        data.onSuccess(response.user);
      }
    }
  );
}

export function* update(api, { data }) {
  yield createBasicSaga(
    api.myProfileUpdateRequest,
    data,
    Creators,
    response => {
      if (
        (response.success || response.user) &&
        data &&
        typeof data.onSuccess === 'function'
      ) {
        data.onSuccess(response.user);
      }
    }
  );
}

export function* updatePicture(api, { data }) {
  yield createBasicSaga(
    api.myProfileUpdatePictureRequest,
    data,
    Creators
  );
}

export function* removePicture(api, { data }) {
  yield createBasicSaga(
    api.myProfileRemovePictureRequest,
    data,
    Creators,
    response => {
      if (
        response.success === true &&
        typeof data.onSuccess === 'function'
      ) {
        data.onSuccess();
      }
    }
  );
}
