import { Types } from 'rdx/actions/contactFormActions';
import { createBasicReducer } from 'rdx/helpers/reducerHelpers';

import { DATA, ERROR, ISLOADING } from 'rdx/constants/common';

export default createBasicReducer(Types, {
  [Types.SUCCESS]: (state, { data }) => ({
    ...state,
    [DATA]: {
      ...state.data,
      ...data,
    },
    [ISLOADING]: false,
    [ERROR]: null,
  }),
});
