// export const API_URL =
//   'https://backend.saudi-trader.dev.lorentus.com/';
export const API_URL = 'https://backend.sauditrader.korit.eu/';
export const PUBLIC_CHECKOUT_KEY =
  'pk_test_b0472830-00d6-40f1-a26f-96e4b3de14ab';

export const APP_STORE =
  'https://apps.apple.com/pl/app/saudi-trader-%D8%B3%D8%B9%D9%88%D8%AF%D9%8A-%D8%AA%D8%B1%D9%8A%D8%AF%D8%B1/id1368641037';
export const GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.sauditrader';

export const LANG = localStorage.getItem('i18nextLng') || 'en';
export const REQUESTS_LANG = 'en';
export const FULL_LANG_NAME = {
  en: 'english',
  ar: 'arabic',
};

export const REPORT_TYPES = [
  'fraud',
  'spam',
  'miscategorized',
  'repetitiveListing',
  'incorrectPrice',
];

export const MILAGE_UNITS = {
  km: 'km',
  mi: 'mi',
};

export const ADS_TYPES = [
  {
    type: '',
    label: 'common.all',
  },
  {
    type: 'car',
    label: 'common.cars',
  },
  {
    type: 'plate',
    label: 'common.numberPlates',
  },
  {
    type: 'phone',
    label: 'common.mobileNumbers',
  },
];

export const PLATE_FILTERS = {
  plate_repeatingLetterAr: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
  ],
  plate_repeatingLetterEn: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
  ],
  plate_repeatingNumber: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
  ],
};

export const CAR_FILTERS = {
  sellerType: [
    {
      value: 'private',
      label: {
        en: 'Private Seller',
        ar: 'بائع خاص',
      },
    },
    {
      value: 'dealer',
      label: {
        en: 'Dealer',
        ar: 'تاجر',
      },
    },
  ],
};

export const PHONE_FILTERS = {
  phone_repeatingNumber: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
  ],
};

export const SORT_BY = [
  {
    value: null,
    label: 'adsList.sortBy',
  },
  {
    value: 'createdAt',
    label: 'adsList.newestToOldest',
  },
  {
    value: '-createdAt',
    label: 'adsList.oldestToNewest',
  },
  {
    value: 'form.price',
    label: 'adsList.priceHighestToLowest',
  },
  {
    value: '-form.price',
    label: 'adsList.priceLowestToHighest',
  },
];

export const DEFAULT_LOCATION = {
  lat: 24.7241504,
  lng: 46.2620508,
};

export const MALE = {
  label: 'newUser1.male',
  value: 'male',
};

export const FEMALE = {
  label: 'newUser1.female',
  value: 'female',
};

export const NO_VALUE = '-';
export const NO_PHONE_NUMBER = 'unavailable';

export const CAR_FIELDS_RULES = {
  minYear: 1885,
  minPrice: 0.01,
  minMilage: '0',
  minHorsePower: 1,
};

export const PLATE_FIELDS_RULES = {
  minPrice: 0.01,
};

export const PHONE_FIELDS_RULES = {
  minPrice: 0.01,
};

export const TOKEN = 'tkn';
export const TOKEN_REFRESH = 'tkn_refresh';
export const TOKEN_EXPIRE_DATE = 'tkn_expire_date';
export const USER_ID = 'user_id';
export const USER_NAME = 'user_name';
