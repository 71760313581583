import { DATA } from 'rdx/constants/common';
import { ADS } from 'rdx/constants/chunks';
import { createBasicActions } from 'rdx/helpers/actionHelpers';

export const { Types, Creators } = createBasicActions(ADS, {
  single: [DATA],
  updateSingle: [DATA],
  report: [DATA],
  renew: [DATA],
});
