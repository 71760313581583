import { lazy } from 'react';

export const Listing = lazy(() =>
  import('components/Listing/Listing')
);

export const ListingChooseCategory = lazy(() =>
  import('components/listings/ChooseCategory/ChooseCategory')
);

export const PlateListing = lazy(() =>
  import('containers/listings/PlateListing')
);

export const PhoneListing = lazy(() =>
  import('containers/listings/PhoneListing')
);

export const CarListing = lazy(() =>
  import('containers/listings/CarListing')
);
