import React from 'react';
import { Redirect } from 'react-router-dom';

import { isAuthenticated } from 'auxiliaries/helpers/authHelpers';

import {
  AboutUs,
  PrivacyPolicy,
  TermsAndConditions,
  Landing,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  DealerRegister,
} from 'constants/lazies/common';

export const routes = [
  {
    path: '/',
    exact: true,
    component: Landing,
  },
  {
    path: '/about-us',
    component: AboutUs,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/login',
    render: () =>
      !isAuthenticated() ? <Login /> : <Redirect to="/" />,
  },
  {
    path: '/register',
    render: () =>
      !isAuthenticated() ? <Register /> : <Redirect to="/" />,
  },
  {
    path: '/forgot-password',
    render: () =>
      !isAuthenticated() ? <ForgotPassword /> : <Redirect to="/" />,
  },
  {
    path: '/reset-password',
    render: () =>
      !isAuthenticated() ? <ResetPassword /> : <Redirect to="/" />,
  },
  {
    path: '/dealer-register',
    render: () =>
      !isAuthenticated() ? <DealerRegister /> : <Redirect to="/" />,
  },
];
