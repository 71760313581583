import { create } from 'apisauce';

import { TOKEN, API_URL } from 'constants/index';

const api = create({
  baseURL: `${API_URL}api`,
  headers: {
    'Content-Type': ' application/json',
  },
});

const getBase64Image = (image, callback) =>
  new Promise((resolve, reject) => {
    try {
      if (typeof image === 'string') {
        resolve(callback(image));
      } else {
        const reader = new FileReader();

        reader.onload = (() => e => {
          const base64String = window.btoa(e.target.result);
          resolve(callback(`data:image/png;base64,${base64String}`));
        })(image);

        reader.readAsBinaryString(image);
      }
    } catch (e) {
      reject(e);
    }
  });

// Auth requests
const loginRequest = data => api.post('/auth/token', data);
const registerRequest = data => api.post('/auth/signup', data);
const forgotPasswordRequest = data => api.post('/auth/forgot', data);
const resetPasswordRequest = data =>
  api.post(`/auth/reset/${data.token}`, data);

// Wishlist requests
const wishlistRequest = data =>
  api.get('/user/wishlist', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
const wishlistRemoveItemRequest = data =>
  api.delete(
    `/user/wishlist/${data.adId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );
const wishlistAddItemRequest = data =>
  api.put(
    `/ad/${data.adId}/wishlist`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );

// Saved Search requests
const savedSearchRequest = data =>
  new Promise(resolve => {
    api
      .get('/user/me', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        },
      })
      .then(response => {
        if (
          !response ||
          !response.data ||
          !response.data.user ||
          !response.data.user.savedSearch
        ) {
          delete response.data.user;
          response.data.savedSearch = [];
          resolve(response);
          return;
        }

        switch (data.type || null) {
          case 'car':
            response.data.savedSearch = response.data.user.savedSearch.filter(
              item => item && item.hasOwnProperty('car_price')
            );
            delete response.data.user;
            resolve(response);
            break;
          case 'plate':
            response.data.savedSearch = response.data.user.savedSearch.filter(
              item => item && item.hasOwnProperty('plate_price')
            );
            delete response.data.user;
            resolve(response);
            break;
          case 'phone':
            response.data.savedSearch = response.data.user.savedSearch.filter(
              item => item && item.hasOwnProperty('phone_price')
            );
            delete response.data.user;
            resolve(response);
            break;
          default:
            response.data.savedSearch =
              response.data.user.savedSearch;
            delete response.data.user;
            resolve(response);
        }
      });
  });

const savedSearchRemoveRequest = data => {
  data.savedSearch.splice(data.index, 1);

  return api.put(
    '/user/me',
    {
      phoneNumber: null,
      savedSearch: data.savedSearch,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

// My profile requests
const myProfileRequest = data =>
  api.get('/user/me', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
const myProfileUpdateRequest = data =>
  api.put('/user/me', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
const myProfileUpdatePictureRequest = image =>
  getBase64Image(image, base64String =>
    api.put(
      '/user/picture',
      {
        picture: base64String,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        },
      }
    )
  );
const myProfileRemovePictureRequest = () =>
  api.delete(
    '/user/picture',
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );
const changePasswordRequest = data =>
  api.put('user/pass', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
const dealerRequest = data =>
  api.get('dealer/me', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });

const dealerUpdatePictureRequest = data =>
  getBase64Image(data, base64String =>
    api.put(
      'dealer/picture',
      {
        picture: base64String,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        },
      }
    )
  );

const dealerDeletePictureRequest = () =>
  api.delete(
    'dealer/picture',
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );

const dealerUpdateRequest = data => {
  const updateDealer = mData =>
    api.put('dealer/me', mData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    });

  return new Promise(resolve => {
    if (data.picture && data.picture[0] instanceof File) {
      dealerUpdatePictureRequest(data.picture[0]).then(mData => {
        const myData = data;
        myData.picture =
          mData.data.dealer && mData.data.dealer.picture
            ? mData.data.dealer.picture
            : null;
        resolve(updateDealer(myData));
      });
    } else if (
      data.picture &&
      (typeof data.picture === 'string' ||
        data.picture instanceof String)
    ) {
      resolve(updateDealer(data));
    } else {
      dealerDeletePictureRequest().then(() => {
        resolve(updateDealer(data));
      });
    }
  });
};

// Filters requests
const getOptionsForFieldRequest = data =>
  api.get('v2/ad/options', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });
const getAllOptionsForFieldRequest = data =>
  api.get('v2/ad/settings', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });

// Other
const basicSearchRequest = (data, headers) =>
  api.get('/ad/new-search', data, headers);

const advancedSearchRequest = data => {
  let headers = {};
  if (localStorage.getItem('tkn')) {
    headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
      },
    };
  }
  delete data.saveSearch;
  return basicSearchRequest(data, headers);
};

const singleAdRequest = data => {
  let headers = {};
  if (localStorage.getItem('tkn')) {
    headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
      },
    };
  }
  delete data.saveSearch;
  return api.get(`/ad/${data.id}`, {}, headers);
};

const reportAnAdRequest = data => {
  let headers = {};
  if (localStorage.getItem('tkn')) {
    headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
      },
    };
  }

  delete data.onSuccess;
  return api.get('v2/ad/report/', data, headers);
};

const renewAnAdRequest = data => api.post(`ad/renew/${data.id}`, {});

const singleAdUpdate = data =>
  new Promise((resolve, reject) => {
    try {
      const myUrl = `/ad/${data.id}`;

      const updateAdFunc = mData => {
        delete mData.id;
        delete mData.onSuccess;
        resolve(
          api.put(myUrl, mData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
            },
          })
        );
      };

      const completedPhotos = myData => {
        getBase64Image(
          typeof myData.form.certificate === 'string'
            ? myData.form.certificate
            : myData.form.certificate[0],
          base64String => {
            myData.form.certificate = base64String;
            if (myData.form.report) {
              getBase64Image(
                typeof myData.form.report === 'string'
                  ? myData.form.report
                  : myData.form.report[0],
                base64String => {
                  myData.form.report = base64String;
                  updateAdFunc(myData);
                }
              );
            } else {
              updateAdFunc(myData);
            }
          }
        );
      };

      completedPhotos(data);
    } catch (e) {
      reject(e);
    }
  });

const makeOfferRequest = data =>
  api.post(`/ad/${data.query}/offer`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });

const addCardRequest = data =>
  api.post('/user/card', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });

const getMyAds = data =>
  api.get('/ad/my', data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });

const removeAd = data =>
  api.delete(
    `/ad/${data.id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
      },
    }
  );

const removeCardRequest = data =>
  api.delete(`/user/card/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tkn')}`,
    },
  });

const placeAdRequest = data =>
  new Promise((resolve, reject) => {
    try {
      const loadUserObject = mData => {
        // Load user object
        myProfileRequest().then(response =>
          resolve(
            api.post(
              '/ad/',
              {
                ...mData,
                user: response.data.user,
                active: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    TOKEN
                  )}`,
                },
              }
            )
          )
        );
      };

      const completedPhotos = myData => {
        getBase64Image(
          typeof myData.form.certificate === 'string'
            ? myData.form.certificate
            : myData.form.certificate[0],
          base64String => {
            myData.form.certificate = base64String;
            if (myData.form.report) {
              getBase64Image(
                typeof myData.form.report === 'string'
                  ? myData.form.report
                  : myData.form.report[0],
                base64String => {
                  myData.form.report = base64String;

                  loadUserObject(myData);
                }
              );
            } else {
              loadUserObject(myData);
            }
          }
        );
      };

      completedPhotos(data);
    } catch (e) {
      reject(e);
    }
  });

const mujazReportRequest = data =>
  getBase64Image(data.registration[0], base64String =>
    api.put(
      'ad/mujaz',
      {
        ...data,
        registration: base64String,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tkn')}`,
        },
      }
    )
  );

const contactFormRequest = data => {
  const sendContactForm = mData => api.post('contact', mData);

  if (data && data.attachment && data.attachment[0]) {
    return getBase64Image(data.attachment[0], base64String =>
      sendContactForm({
        ...data,
        attachment: base64String,
      })
    );
  } else {
    return sendContactForm({
      ...data,
      attachment: '',
    });
  }
};

export default {
  loginRequest,
  registerRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  wishlistRequest,
  wishlistRemoveItemRequest,
  wishlistAddItemRequest,
  savedSearchRequest,
  savedSearchRemoveRequest,
  myProfileRequest,
  myProfileUpdateRequest,
  myProfileUpdatePictureRequest,
  myProfileRemovePictureRequest,
  basicSearchRequest,
  advancedSearchRequest,
  singleAdRequest,
  reportAnAdRequest,
  renewAnAdRequest,
  singleAdUpdate,
  placeAdRequest,
  changePasswordRequest,
  dealerRequest,
  dealerUpdateRequest,
  getOptionsForFieldRequest,
  getAllOptionsForFieldRequest,
  makeOfferRequest,
  addCardRequest,
  removeAd,
  removeCardRequest,
  getMyAds,
  mujazReportRequest,
  contactFormRequest,
};
