import { Types } from 'rdx/actions/adsActions';
import { createBasicReducer } from 'rdx/helpers/reducerHelpers';
import { ERROR, ISLOADING } from 'rdx/constants/common';

export default createBasicReducer(Types, {
  [Types.SINGLE]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
  [Types.UPDATE_SINGLE]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
  [Types.REPORT]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
  [Types.RENEW]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
});
