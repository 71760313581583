import { call, put } from 'redux-saga/effects';
import { prop } from 'ramda';

import { addLocalAuthData } from 'auxiliaries/helpers/authHelpers';

import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/registerActions';
import { Creators as DealerCreators } from 'rdx/actions/dealerActions';

export function* request(api, { data }) {
  const dealerData = {
    picture: data.picture,
    title: data.title,
    websiteUrl: data.websiteUrl,
    description: data.description,
    address: data.address,
    location: data.location,
    facebook: data.facebook,
    twitter: data.twitter,
    linkedin: data.linkedin,
    pinterest: data.pinterest,
  };

  delete data.picture;
  delete data.title;
  delete data.websiteUrl;
  delete data.description;
  delete data.address;
  delete data.location;
  delete data.facebook;
  delete data.twitter;
  delete data.linkedin;
  delete data.pinterest;

  const registerData = { ...data };

  if (dealerData.title) {
    registerData.role = 'dealer';

    try {
      const response = yield call(api.registerRequest, registerData);

      if (response && response.ok) {
        console.log('Results: ', response.data);
        yield put(Creators.success(response.data));
        addLocalAuthData(response.data.resp);
        yield put(DealerCreators.update(dealerData));
      } else {
        const mData = prop('data', response);

        if (mData === null) {
          yield put(Creators.failure('Internal server error'));
        } else {
          yield put(Creators.failure(response.data));
        }

        console.log('Failed: ', response.data);
      }
    } catch (e) {
      console.log('Failed: ', e.message, e);
      yield put(Creators.failure(e.message));
    }
  } else {
    // If is buyer register
    registerData.role = 'buyer';
    yield createBasicSaga(
      api.registerRequest,
      registerData,
      Creators
    );
  }
}
