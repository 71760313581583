import { Types } from 'rdx/actions/myProfileActions';
import { createBasicReducer } from 'rdx/helpers/reducerHelpers';
import { ERROR, ISLOADING } from 'rdx/constants/common';

export default createBasicReducer(Types, {
  [Types.UPDATE]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
  [Types.UPDATE_PICTURE]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
  [Types.REMOVE_PICTURE]: state => ({
    ...state,
    [ISLOADING]: true,
    [ERROR]: null,
  }),
});
