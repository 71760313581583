import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/savedSearchActions';

export function* request(api, { data }) {
  yield createBasicSaga(api.savedSearchRequest, data, Creators);
}

export function* remove(api, { data }) {
  yield createBasicSaga(
    api.savedSearchRemoveRequest,
    data,
    Creators,
    response => {
      if (
        (response.success || response.user) &&
        data &&
        typeof data.onSuccess === 'function'
      ) {
        data.onSuccess();
      }
    }
  );
}
