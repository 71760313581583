import React from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { toast } from 'react-toastify';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyAH1Gl1Yq2ZSWEmLR3BA5nGOnpbmV2td00",
    authDomain: "saudi-trader-c1b6d.firebaseapp.com",
    databaseURL: "https://saudi-trader-c1b6d.firebaseio.com",
    projectId: "saudi-trader-c1b6d",
    storageBucket: "saudi-trader-c1b6d.appspot.com",
    messagingSenderId: "71855086616",
    appId: "1:71855086616:web:e850bd5a3247b5d6ce776d",
    measurementId: "G-H4QJG72GP4"
  });
}

export const askForPermissionToReceiveNotifications = async () => {
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => {
    if (payload && payload.notification) {
      const Msg = () => (
        <>
          <h2 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '3px' }}>{ payload.notification.title }</h2>
          <p style={{ fontSize: '14px', fontWeight: 'normal', marginTop: 0, marginBottom: 0 }}>{ payload.notification.body }</p>
        </>
      )
      toast.info(<Msg />, { autoClose: 10000, pauseOnHover: true })
    }
  });
  if (!localStorage.getItem('device_token')) {
    try {
      await messaging.requestPermission();
      const token = await messaging.getToken({ vapidKey: 'BNlrQUSGP46lXfO2IeWbbHvvfEQ6at876mZOJgUwvTdpaZxOJj26L-466_rlO4duAmdM2Pp9scpdCJndeUE-uSw' });

      if (token) {
        localStorage.setItem('device_token', token);
      }

      return token;
    } catch (error) {
      console.error(error);
    }
  }
}
