import { call, put } from 'redux-saga/effects';
import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/adsActions';

export function* request(api, { data }) {
  yield createBasicSaga(
    api.advancedSearchRequest,
    data,
    Creators,
    response => {
      if (response.success && typeof data.onSuccess === 'function') {
        data.onSuccess();
      }
    }
  );
}

export function* updateSingleRequest(api, { data }) {
  const onSuccess = data.onSuccess;
  yield createBasicSaga(
    api.singleAdUpdate,
    data,
    Creators,
    response => {
      if (response.ad && typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  );
}

export function* singleRequest(api, { data }) {
  try {
    const response = yield call(api.singleAdRequest, data);
    const onSuccess = data.onSuccess;

    if (response && response.ok) {
      console.log('Results: ', response.data);
      yield put(Creators.success(response.data));

      if (onSuccess) {
        yield onSuccess(response.data);
      }
    } else {
      console.log('Failed: ', response.data);
      yield put(Creators.failure(response.data));
    }
  } catch (e) {
    console.log('Failed: ', e.message, e);
    yield put(Creators.failure(e.message));
  }
}

export function* reportRequest(api, { data }) {
  const onSuccess = data.onSuccess;
  yield createBasicSaga(
    api.reportAnAdRequest,
    data,
    Creators,
    response => {
      if (response.ad && typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  );
}

export function* renewRequest(api, { data }) {
  const onSuccess = data.onSuccess;
  yield createBasicSaga(
    api.renewAnAdRequest,
    data,
    Creators,
    response => {
      if (response.ad && typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  );
}
