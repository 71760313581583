import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/dealerActions';

function* onSuccessUpdateRequest() {
  yield put(push('/user/my-profile'));
}

export function* request(api, { data }) {
  yield createBasicSaga(api.dealerRequest, data, Creators);
}

export function* update(api, { data }) {
  yield createBasicSaga(
    api.dealerUpdateRequest,
    data,
    Creators,
    onSuccessUpdateRequest
  );
}
