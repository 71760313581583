import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/placeAdActions';

function* onSuccessRequest({ ad }) {
  if (ad && ad._id) {
    yield put(push(`/ad/details/${ad._id}`));
  }
}

export function* request(api, { data }) {
  yield createBasicSaga(
    api.placeAdRequest,
    data,
    Creators,
    onSuccessRequest
  );
}
