import enTrans from './trans/en.json';
import arTrans from './trans/ar.json';

export default {
  en: {
    translation: enTrans,
  },
  ar: {
    translation: arTrans,
  },
};
