import { Types } from 'rdx/actions/advancedSearchActions';
import { createBasicReducer } from 'rdx/helpers/reducerHelpers';

const extraState = {
  activeStatus: false,
};

export default createBasicReducer(
  Types,
  {
    [Types.SET_ACTIVE_STATUS]: (state, { activeStatus }) => ({
      ...state,
      activeStatus,
    }),
  },
  extraState
);
