import React from 'react';

import { ReactComponent as Loading } from 'assets/images/loading.svg';

const Loader = ({ extraCN }) => (
  <div className={extraCN}>
    <Loading />
  </div>
);

export default Loader;
