import { lazy } from 'react';

export const PlaceAdCarInformation = lazy(() =>
  import('containers/PlaceAd/car/Information')
);

export const PlaceAdCarExtraInformation = lazy(() =>
  import('containers/PlaceAd/car/ExtraInformation')
);

export const PlaceAdCarUploadImage = lazy(() =>
  import('containers/PlaceAd/car/UploadImage')
);

export const PlaceAdCarContactInfo = lazy(() =>
  import('containers/PlaceAd/car/ContactInfo')
);

export const PlaceAdCarReview = lazy(() =>
  import('containers/PlaceAd/car/Review')
);
