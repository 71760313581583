import { onlyAuthenticated } from 'auxiliaries/helpers/authHelpers';

import {
  PlaceAdCarContactInfo,
  PlaceAdCarExtraInformation,
  PlaceAdCarInformation,
  PlaceAdCarReview,
  PlaceAdCarUploadImage,
} from 'constants/lazies/placeAd/car';

export const routes = [
  {
    path: '/place-ad/car/information',
    render: props => onlyAuthenticated(PlaceAdCarInformation, props),
  },
  {
    path: '/place-ad/car/extra-information',
    render: props =>
      onlyAuthenticated(PlaceAdCarExtraInformation, props),
  },
  {
    path: '/place-ad/car/upload-image',
    render: props => onlyAuthenticated(PlaceAdCarUploadImage, props),
  },
  {
    path: '/place-ad/car/contact-info',
    render: props => onlyAuthenticated(PlaceAdCarContactInfo, props),
  },
  {
    path: '/place-ad/car/review',
    render: props => onlyAuthenticated(PlaceAdCarReview, props),
  },
];
