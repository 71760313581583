import { lazy } from 'react';

export const PlaceAdPlateNumberInformation = lazy(() =>
  import('containers/PlaceAd/plateNumber/Information')
);

export const PlaceAdPlateNumberContactInfo = lazy(() =>
  import('containers/PlaceAd/plateNumber/ContactInfo')
);

export const PlaceAdPlateNumberReview = lazy(() =>
  import('containers/PlaceAd/plateNumber/Review')
);
