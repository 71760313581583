import { App } from 'constants/lazies/common';

import { routes as commonRoutes } from './routes/common';
import { routes as listingRoutes } from './routes/listing';
import { routes as placeAdRoutes } from './routes/placeAd';
import { routes as userRoutes } from './routes/user';
import { routes as adRoutes } from './routes/ad';

export const routes = [
  {
    component: App,
    routes: [
      ...commonRoutes,
      ...listingRoutes,
      ...placeAdRoutes,
      ...userRoutes,
      ...adRoutes,
    ],
  },
];
