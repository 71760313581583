import { onlyAuthenticated } from 'auxiliaries/helpers/authHelpers';

import { PlaceAdChooseCategory } from 'constants/lazies/placeAd';
import { routes as placeAdCarRoutes } from 'constants/routes/placeAd/car';
import { routes as placeAdPlateNumberRoutes } from 'constants/routes/placeAd/plateNumber';
import { routes as placeAdMobileNumberRoutes } from 'constants/routes/placeAd/mobileNumber';

export const routes = [
  {
    path: '/place-ad/choose-category',
    render: props => onlyAuthenticated(PlaceAdChooseCategory, props),
  },
  ...placeAdCarRoutes,
  ...placeAdPlateNumberRoutes,
  ...placeAdMobileNumberRoutes,
];
