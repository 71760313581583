import { Creators } from 'rdx/actions/cardsActions';
import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Creators as MyProfileCreators } from 'rdx/actions/myProfileActions';

export function* request(api, { data }) {
  yield createBasicSaga(
    api.addCardRequest,
    data,
    Creators,
    onAddedCardSuccess,
    true
  );
}

function* onAddedCardSuccess() {
  yield put(MyProfileCreators.request());
  yield put(push('/user/my-payments/'));
}

function* onRemoveCardSuccess() {
  yield put(MyProfileCreators.request());
}

export function* removeCardRequest(api, { data }) {
  yield createBasicSaga(
    api.removeCardRequest,
    data,
    Creators,
    onRemoveCardSuccess
  );
}
