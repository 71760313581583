import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/wishlistActions';

export function* request(api, { data }) {
  yield createBasicSaga(api.wishlistRequest, data, Creators);
}

export function* add(api, { data }) {
  yield createBasicSaga(
    api.wishlistAddItemRequest,
    data,
    Creators,
    response => {
      if (response.success && typeof data.onSuccess === 'function') {
        data.onSuccess();
      }
    }
  );
}

export function* remove(api, { data }) {
  yield createBasicSaga(
    api.wishlistRemoveItemRequest,
    data,
    Creators,
    response => {
      if (response.success && typeof data.onSuccess === 'function') {
        data.onSuccess();
      }
    }
  );
}
