import { onlyAuthenticated } from 'auxiliaries/helpers/authHelpers';

import {
  PlaceAdPhoneNumberInformation,
  PlaceAdPhoneNumberContactInfo,
  PlaceAdPhoneNumberReview,
} from 'constants/lazies/placeAd/mobileNumber';

export const routes = [
  {
    path: '/place-ad/mobile-number/information',
    render: props =>
      onlyAuthenticated(PlaceAdPhoneNumberInformation, props),
  },
  {
    path: '/place-ad/mobile-number/contact-info',
    render: props =>
      onlyAuthenticated(PlaceAdPhoneNumberContactInfo, props),
  },
  {
    path: '/place-ad/mobile-number/review',
    render: props =>
      onlyAuthenticated(PlaceAdPhoneNumberReview, props),
  },
];
