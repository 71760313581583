import { createActions } from 'reduxsauce';

import { DATA, ERROR } from 'rdx/constants/common';

export const createBasicActions = (prefix = '', extraActions) =>
  createActions(
    {
      request: [DATA],
      success: [DATA],
      failure: [ERROR],
      reset: null,
      ...extraActions,
    },
    {
      prefix: `@${prefix}/`,
    }
  );
