export const DATA = 'data';
export const ERROR = 'error';
export const ERR_MSG = 'errMsg';
export const ISLOADING = 'isLoading';
export const USER = 'user';
export const CITY = 'city';
export const MAKE = 'make';
export const MODEL = 'model';
export const COUNTRY = 'country';
export const PLATE_TYPE = 'plateType';
export const BODY_TYPE = 'bodyType';
export const TOTAL = 'total';
export const OPERATOR = 'operator';
export const COLOR = 'color';
export const INTERIOR_COLOR = 'interiorColor';
export const EXTRA_FEATURES = 'extraFeatures';
export const TRANSMISSION_TYPE = 'transmissionType';
export const CONDITION = 'condition';
export const MECHANICAL_CONDITION = 'mechanicalCondition';
export const HISTORY_SUMMARY = 'historySummary';
export const REGIONAL_SPECS = 'regionalSpecs';
export const CYLINDERS = 'cylinders';
export const DOORS = 'doors';
export const WARRANTY = 'warranty';
export const FUEL_TYPE = 'fuelType';
export const PLATE_SOURCE = 'plateSource';
export const MOBILE_SOURCE = 'mobileSource';
