import { takeLatest, all } from 'redux-saga/effects';

import { Types as LoginTypes } from 'rdx/actions/loginActions';
import { Types as RegisterTypes } from 'rdx/actions/registerActions';
import { Types as ForgotPasswordTypes } from 'rdx/actions/forgotPasswordActions';
import { Types as ResetPasswordTypes } from 'rdx/actions/resetPasswordActions';
import { Types as BasicSearchTypes } from 'rdx/actions/basicSearchActions';
import { Types as AdvancedSearchTypes } from 'rdx/actions/advancedSearchActions';
import { Types as AdsTypes } from 'rdx/actions/adsActions';
import { Types as WishlistTypes } from 'rdx/actions/wishlistActions';
import { Types as SavedSearchTypes } from 'rdx/actions/savedSearchActions';
import { Types as DetailPageTypes } from 'rdx/actions/detailPageActions';
import { Types as PlaceAdTypes } from 'rdx/actions/placeAdActions';
import { Types as MyProfileTypes } from 'rdx/actions/myProfileActions';
import { Types as ChangePasswordTypes } from 'rdx/actions/changePasswordActions';
import { Types as DealerTypes } from 'rdx/actions/dealerActions';
import { Types as GetOptionsForFieldTypes } from 'rdx/actions/getOptionsForFieldActions';
import { Types as GetAllOptionsForFieldTypes } from 'rdx/actions/getAllOptionsForFieldActions';
import { Types as MakeOfferTypes } from 'rdx/actions/offerActions';
import { Types as CardsTypes } from 'rdx/actions/cardsActions';
import { Types as MyAdsTypes } from 'rdx/actions/myAdsActions';
import { Types as MujazReportTypes } from 'rdx/actions/mujazReportActions';
import { Types as ContactFormTypes } from 'rdx/actions/contactFormActions';

import api from './api';

import { request as myAdsRequest, removeAd } from './myAdsSagas';
import { request as loginRequest } from './loginSagas';
import { request as registerRequest } from './registerSagas';
import { request as forgotPasswordRequest } from './forgotPasswordSagas';
import { request as resetPasswordRequest } from './resetPasswordSagas';
import { request as basicSearchRequest } from './basicSearchSagas';
import { request as advancedSearchRequest } from './advancedSearchSagas';
import {
  request as adsRequest,
  singleRequest as adsSingleRequest,
  updateSingleRequest as adsUpdateSingleRequest,
  reportRequest as adsReportRequest,
  renewRequest as adsRenewRequest,
} from './adsSagas';
import { request as offerRequest } from './offerSagas';
import {
  request as cardsRequest,
  removeCardRequest,
} from './cardsSagas';
import {
  request as wishlistRequest,
  add as wishlistAddRequest,
  remove as wishlistRemoveRequest,
} from './wishlistSagas';
import {
  request as savedSearchRequest,
  remove as savedSearchRemoveRequest,
} from './savedSearchSagas';
import { request as detailPageRequest } from './detailPageSagas';
import { request as placeAdRequest } from './placeAdSagas';
import {
  request as myProfileRequest,
  update as myProfileUpdateRequest,
  updatePicture as myProfileUpdatePictureRequest,
  removePicture as myProfileRemovePictureRequest,
} from './myProfileSagas';
import { request as changePasswordRequest } from './changePasswordSagas';
import {
  request as dealerRequest,
  update as dealerUpdateRequest,
} from './dealerSagas';
import { request as getOptionsForFieldRequest } from './getOptionsForFieldSagas';
import { request as getAllOptionsForFieldRequest } from './getAllOptionsForFieldSagas';
import { request as mujazReportRequest } from './mujazReportSagas';
import { request as contactFormRequest } from './contactFormSagas';

export default function* rootSaga() {
  yield all([
    takeLatest(LoginTypes.REQUEST, loginRequest, api),
    takeLatest(RegisterTypes.REQUEST, registerRequest, api),
    takeLatest(
      ForgotPasswordTypes.REQUEST,
      forgotPasswordRequest,
      api
    ),
    takeLatest(ResetPasswordTypes.REQUEST, resetPasswordRequest, api),
    takeLatest(BasicSearchTypes.REQUEST, basicSearchRequest, api),
    takeLatest(
      AdvancedSearchTypes.REQUEST,
      advancedSearchRequest,
      api
    ),
    takeLatest(CardsTypes.REQUEST, cardsRequest, api),
    takeLatest(MyAdsTypes.REQUEST, myAdsRequest, api),
    takeLatest(MyAdsTypes.REMOVE_AD, removeAd, api),
    takeLatest(
      CardsTypes.REMOVE_CARD_REQUEST,
      removeCardRequest,
      api
    ),
    takeLatest(MakeOfferTypes.REQUEST, offerRequest, api),
    takeLatest(AdsTypes.REQUEST, adsRequest, api),
    takeLatest(AdsTypes.SINGLE, adsSingleRequest, api),
    takeLatest(AdsTypes.UPDATE_SINGLE, adsUpdateSingleRequest, api),
    takeLatest(AdsTypes.REPORT, adsReportRequest, api),
    takeLatest(AdsTypes.RENEW, adsRenewRequest, api),
    takeLatest(WishlistTypes.REQUEST, wishlistRequest, api),
    takeLatest(WishlistTypes.ADD, wishlistAddRequest, api),
    takeLatest(WishlistTypes.REMOVE, wishlistRemoveRequest, api),
    takeLatest(SavedSearchTypes.REQUEST, savedSearchRequest, api),
    takeLatest(
      SavedSearchTypes.REMOVE,
      savedSearchRemoveRequest,
      api
    ),
    takeLatest(DetailPageTypes.REQUEST, detailPageRequest, api),
    takeLatest(PlaceAdTypes.REQUEST, placeAdRequest, api),
    takeLatest(MyProfileTypes.REQUEST, myProfileRequest, api),
    takeLatest(MyProfileTypes.UPDATE, myProfileUpdateRequest, api),
    takeLatest(
      MyProfileTypes.UPDATE_PICTURE,
      myProfileUpdatePictureRequest,
      api
    ),
    takeLatest(
      MyProfileTypes.REMOVE_PICTURE,
      myProfileRemovePictureRequest,
      api
    ),
    takeLatest(
      ChangePasswordTypes.REQUEST,
      changePasswordRequest,
      api
    ),
    takeLatest(DealerTypes.REQUEST, dealerRequest, api),
    takeLatest(DealerTypes.UPDATE, dealerUpdateRequest, api),
    takeLatest(
      GetOptionsForFieldTypes.REQUEST,
      getOptionsForFieldRequest,
      api
    ),
    takeLatest(
      GetAllOptionsForFieldTypes.REQUEST,
      getAllOptionsForFieldRequest,
      api
    ),
    takeLatest(MujazReportTypes.REQUEST, mujazReportRequest, api),
    takeLatest(ContactFormTypes.REQUEST, contactFormRequest, api),
  ]);
}
