import { Details, Renew } from 'constants/lazies/ad';

export const routes = [
  {
    path: '/ad/details/:id',
    component: Details,
  },
  {
    path: '/ad-renew/:id',
    component: Renew,
  },
];
