import { lazy } from 'react';

export const MyProfile = lazy(() =>
  import('components/MyProfile/MyProfile')
);

export const MyProfileBox = lazy(() =>
  import('containers/MyProfileBox')
);

export const DealerBox = lazy(() => import('containers/DealerBox'));

export const ChangePasswordBox = lazy(() =>
  import('containers/ChangePasswordBox')
);

export const MyWishlist = lazy(() =>
  import('components/MyWishlist/MyWishlist')
);

export const MySavedSearch = lazy(() =>
  import('components/MySavedSearch/MySavedSearch')
);

export const MyAds = lazy(() => import('components/MyAds/MyAds'));

export const EditMyAd = lazy(() =>
  import('containers/MyAds/EditMyAd')
);

export const CarInfoMyAd = lazy(() =>
  import('containers/MyAds/CarInfoMyAd')
);

export const PlateInfoMyAd = lazy(() =>
  import('containers/MyAds/PlateInfoMyAd')
);

export const MobileInfoMyAd = lazy(() =>
  import('containers/MyAds/MobileInfoMyAd')
);

export const PreviewMyAd = lazy(() =>
  import('containers/MyAds/PreviewMyAd/')
);

export const PersonalInfoMyAd = lazy(() =>
  import('containers/MyAds/PersonalInfoMyAd')
);

export const UpdateImagesMyAd = lazy(() =>
  import('components/MyAds/UpdateImagesMyAd/UpdateImagesMyAd')
);

export const MyAdsList = lazy(() =>
  import('containers/MyAds/MyAdsList')
);

export const MyPayments = lazy(() =>
  import('components/MyPayments/MyPayments')
);

export const SavedDetails = lazy(() =>
  import('containers/MyPayments/SavedDetails')
);

export const NewPaymentDetails = lazy(() =>
  import('containers/MyPayments/NewPaymentDetails')
);
