import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/offerActions';

export function* request(api, { data }) {
  yield createBasicSaga(
    api.makeOfferRequest,
    data,
    Creators,
    null,
    true
  );
}
