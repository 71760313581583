import { createReducer } from 'reduxsauce';

import { DATA, ERROR, ISLOADING } from 'rdx/constants/common';

const initialState = {
  [ISLOADING]: false,
  [DATA]: null,
  [ERROR]: null,
};

export const createBasicReducer = (
  Types,
  extraTypes,
  extraInitialState
) =>
  createReducer(
    {
      ...initialState,
      ...extraInitialState,
    },
    {
      [Types.REQUEST]: state => ({
        ...state,
        [ISLOADING]: true,
        [ERROR]: null,
      }),
      [Types.SUCCESS]: (state, { data }) => ({
        ...state,
        data,
        [ISLOADING]: false,
        [ERROR]: null,
      }),
      [Types.FAILURE]: (state, { error }) => ({
        ...state,
        [ISLOADING]: false,
        [ERROR]: error,
      }),
      [Types.RESET]: () => ({
        initialState,
      }),
      ...extraTypes,
    }
  );
