export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORM = 'form';
export const ROUTER = 'router';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const BASIC_SEARCH = 'basicSearch';
export const ADVANCED_SEARCH = 'advancedSearch';
export const ADS = 'ads';
export const AD = 'ad';
export const WISHLIST = 'wishlist';
export const DETAIL_PAGE = 'detailPage';
export const PLACE_AD = 'placeAd';
export const SAVED_SEARCH = 'savedSearch';
export const MY_PROFILE = 'myProfile';
export const CHANGE_PASSWORD = 'changePassword';
export const DEALER = 'dealer';
export const GET_OPTIONS_FOR_FIELD = 'getOptionsForField';
export const GET_ALL_OPTIONS_FOR_FIELD = 'getAllOptionsForField';
export const MAKE_OFFER = 'makeOffer';
export const CARDS = 'cards';
export const MY_ADS = 'myAds';
export const REPORT = 'report';
export const MUJAZ_REPORT = 'mujazReport';
export const CONTACT_FORM = 'contactForm';
