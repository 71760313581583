import { call, put } from 'redux-saga/effects';
import { prop } from 'ramda';

export function* createBasicSaga(
  apiCall,
  data,
  Creators,
  onSuccess,
  humanErrors = false
) {
  try {
    const response = yield call(apiCall, data);

    if (response && response.ok) {
      // console.log('Results: ', response.data);
      yield put(Creators.success(response.data));

      if (onSuccess) {
        yield onSuccess(response.data);
      }
    } else {
      const data = prop('data', response);

      if (data === null) {
        yield put(Creators.failure('Internal server error'));
      } else {
        yield put(
          Creators.failure(
            humanErrors
              ? 'Unexpected error. Contact with administrator.'
              : response.data
          )
        );
      }

      console.log('Failed: ', response.data);
    }
  } catch (e) {
    console.log('Failed: ', e.message, e);
    yield put(
      Creators.failure(
        humanErrors
          ? 'Unexpected error. Contact with administrator.'
          : e.message
      )
    );
  }
}
