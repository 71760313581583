import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { addLocalAuthData } from 'auxiliaries/helpers/authHelpers';

import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/loginActions';
import { Creators as MyProfileCreators } from 'rdx/actions/myProfileActions';

import { USER_ID, USER_NAME } from 'constants/index';

function* onSuccessRequest(resp, api) {
  addLocalAuthData(resp);

  yield createBasicSaga(
    api.myProfileRequest,
    {},
    MyProfileCreators,
    onSuccessUserRequest
  );
}

function* onSuccessUserRequest(resp) {
  localStorage.setItem(USER_ID, resp.user._id);
  localStorage.setItem(USER_NAME, resp.user.username);
  yield put(push('/listing'));
}

export function* request(api, { data }) {
  yield createBasicSaga(api.loginRequest, data, Creators, resp =>
    onSuccessRequest(resp, api)
  );
}
