import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';

import configureStore from 'rdx/configureStore';

import Loader from 'components/common/Loader/Loader';

import { routes } from 'constants/routes';

import i18n from './i18n';
import registerServiceWorker from './registerServiceWorker';
import { initializeFirebase, askForPermissionToReceiveNotifications } from './push';

import './styles/index.scss';

const history = createBrowserHistory();
const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState, history);

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      {renderRoutes(routes, {
        store,
        history,
      })}
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root'),
  () => {
    if (i18n.language === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    }
  }
);

initializeFirebase();
askForPermissionToReceiveNotifications();
registerServiceWorker();
