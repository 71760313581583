import { createBasicSaga } from 'rdx/helpers/sagaHelpers';
import { Creators } from 'rdx/actions/myAdsActions';
import { put } from 'redux-saga/effects';

export function* request(api, { data }) {
  yield createBasicSaga(api.getMyAds, data, Creators);
}

function* onRemoveAdSuccess() {
  yield put(Creators.request());
}

export function* removeAd(api, { data }) {
  yield createBasicSaga(
    api.removeAd,
    data,
    Creators,
    onRemoveAdSuccess,
    true
  );
}
