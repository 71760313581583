import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import {
  LOGIN,
  ROUTER,
  FORM,
  REGISTER,
  FORGOT_PASSWORD,
  BASIC_SEARCH,
  ADVANCED_SEARCH,
  RESET_PASSWORD,
  ADS,
  WISHLIST,
  SAVED_SEARCH,
  DETAIL_PAGE,
  MY_PROFILE,
  CHANGE_PASSWORD,
  DEALER,
  GET_OPTIONS_FOR_FIELD,
  GET_ALL_OPTIONS_FOR_FIELD,
  MAKE_OFFER,
  CARDS,
  MY_ADS,
  MUJAZ_REPORT,
  CONTACT_FORM,
} from 'rdx/constants/chunks';

import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import basicSearchReducer from './basicSearchReducer';
import advancedSearchReducer from './advancedSearchReducer';
import resetPasswordReducer from './resetPasswordReducer';
import adsReducer from './adsReducer';
import wishlistReducer from './wishlistReducer';
import savedSearchReducer from './savedSearchReducer';
import detailPageReducer from './detailPageReducer';
import myProfileReducer from './myProfileReducer';
import changePasswordReducer from './changePasswordReducer';
import dealerReducer from './dealerReducer';
import getOptionsForFieldReducer from './getOptionsForFieldReducer';
import getAllOptionsForFieldReducer from './getAllOptionsForFieldReducer';
import offerReducer from './offerReducer';
import cardsReducer from './cardsReducer';
import myAdsReducer from './myAdsReducer';
import mujazReportReducer from './mujazReportReducer';
import contactFormReducer from './contactFormReducer';

const rootReducer = history =>
  combineReducers({
    [ROUTER]: connectRouter(history),
    [LOGIN]: loginReducer,
    [FORM]: formReducer,
    [REGISTER]: registerReducer,
    [FORGOT_PASSWORD]: forgotPasswordReducer,
    [BASIC_SEARCH]: basicSearchReducer,
    [ADVANCED_SEARCH]: advancedSearchReducer,
    [RESET_PASSWORD]: resetPasswordReducer,
    [ADS]: adsReducer,
    [WISHLIST]: wishlistReducer,
    [SAVED_SEARCH]: savedSearchReducer,
    [DETAIL_PAGE]: detailPageReducer,
    [MY_PROFILE]: myProfileReducer,
    [CHANGE_PASSWORD]: changePasswordReducer,
    [DEALER]: dealerReducer,
    [GET_OPTIONS_FOR_FIELD]: getOptionsForFieldReducer,
    [GET_ALL_OPTIONS_FOR_FIELD]: getAllOptionsForFieldReducer,
    [MAKE_OFFER]: offerReducer,
    [CARDS]: cardsReducer,
    [MY_ADS]: myAdsReducer,
    [MUJAZ_REPORT]: mujazReportReducer,
    [CONTACT_FORM]: contactFormReducer,
  });

export default rootReducer;
